<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-11-10 17:58:54
 * @LastEditors: liutq
 * @LastEditTime: 2025-03-21 16:47:29
-->
<template>
    <div :class="['setting-user', $i18n.locale]">
        <div class="header-menu">
            <span>
                <img src="@/assets/images/sidebar/arrow-left.png" alt class="menu-info" @click.prevent="onClickLeft()" />
            </span>
        </div>
        <!-- <van-nav-bar :left-text="$t('sidebar.setting')" left-arrow @click-left="onClickLeft()" /> -->
        <div class="content">
            <div class="visbody-fit">
                <span class="title">{{ $t('sidebar.form.labelList[1]') }}</span>
            </div>
            <van-cell-group>
                <van-cell :title="$t('sidebar.unit[0]')" clickable @click="onClickUnit('metric')">
                    <template #right-icon>
                        <van-icon v-if="unit === 'metric'" name="success" class="current" />
                    </template>
                </van-cell>
                <van-cell :title="$t('sidebar.unit[1]')" clickable @click="onClickUnit('imperial')">
                    <template #right-icon>
                        <van-icon v-if="unit === 'imperial'" name="success" class="current" />
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
        <div class="content">
            <div class="title">{{ $t('sidebar.form.labelList[2]') }}</div>
            <van-cell-group>
                <van-cell v-for="language in languages" :key="language.label" :title="language.label" clickable
                    @click="onClickLang(language.value)">
                    <template #right-icon>
                        <van-icon v-if="lang === language.value" name="success" class="current" />
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
    </div>
</template>

<script>
import { Locale } from 'vant';
import enUS from 'vant/lib/locale/lang/en-US';
import zhCN from 'vant/lib/locale/lang/zh-CN'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import ruRU from '@/assets/js/lang/ru-RU'
import frFR from 'vant/lib/locale/lang/fr-FR'
import zhHK from 'vant/lib/locale/lang/zh-HK'
import zhTW from 'vant/lib/locale/lang/zh-TW'
import idID from '@/assets/js/lang/id-ID'
import ptPTO from '@/assets/js/lang/pt-PTO'
import arSA from '@/assets/js/lang/ar-AR'
import trTR from 'vant/lib/locale/lang/tr-TR'
import deDE from 'vant/lib/locale/lang/de-DE'
import thTH from '@/assets/js/lang/th-TH'
import elGR from '@/assets/js/lang/el-GR'

import { languageConfig, languageConfigM60 } from '@/assets/js/langObject'
export default {
    data() {
        return {
            lang: '',
            unit: localStorage.getItem('unit'),
            languages: JSON.parse(window.localStorage.getItem('deviceType')) == 4 ? languageConfigM60 : languageConfig,
        }
    },
    created() {
        this.lang = this.$i18n.locale
    },
    methods: {
        onClickUnit(unit) {
            this.unit = unit
            localStorage.setItem('unit', unit)
        },
        onClickLang(lang) {
            this.$i18n.locale = lang
            this.lang = lang
            localStorage.setItem('lang', lang)
            if (lang === 'ja-JP') {
                Locale.use('ja-JP', jaJP); 
            } else if ( lang === 'zh-CN') {
                Locale.use( 'zh-CN', zhCN)
            } else if ( lang === 'fr-FR') {
                Locale.use( 'fr-FR', frFR)
            }else if ( lang === 'zh-HK') {
                Locale.use( 'zh-HK', zhHK)
            }else if ( lang === 'zh-TW') {
                Locale.use( 'zh-TW', zhTW)
            }else if ( lang === 'id-ID') {
                Locale.use( 'id-ID', idID)
            }else if ( lang === 'tr-TR') {
                Locale.use( 'tr-TR', trTR)
            }else if ( lang === 'de-DE') {
                Locale.use( 'de-DE', deDE)
            }else if ( lang === 'ru-RU') {
                Locale.use( 'ru-RU', ruRU)
            }else if ( lang === 'pt-PTO') {
                Locale.use( 'pt-PTO', ptPTO)
            }else if(lang === 'th-TH'){
                Locale.use( 'th-TH', thTH)
            }else if(lang === 'ar-AR'){
                 Locale.use( 'ar-AR', arSA)
            }else if(lang === 'el-GR'){
                 Locale.use( 'el-GR', elGR)
            }
            else {
                Locale.use('en-US', enUS)
            }
        },
        onClickLeft() {
            this.$router.push({
                name: 'report',
                params: { type: 1 }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.setting-user {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #262849, #07090f);
    text-align: left;
    overflow: scroll;

    .content {
        margin-top: 0px;

        .visbody-fit {
            width: 100%;
            // padding: 30px 0 10px;
            margin: 7px auto;
            text-align: left;
            // border-bottom: 1px solid rgba(255, 255, 255, 0.2);

            .title {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 1px;
                padding-left: 16px;
                padding-top: 16px;
                padding-bottom: 12px;
                font-weight: 400;
            }
        }
    }

    .header-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
        width: 100%;
        top: 0;
        z-index: 100;

        >span {
            img {
                display: block;
            }
        }

        .menu-info {
            width: 24px;
            height: 24px;
        }
    }

    .current {
        color: #838491;
        display: flex;
        align-items: center;
    }

    .van-nav-bar {
        background-color: #222341;

        &::after {
            border-bottom: 1px solid #8f909e;
        }

        /deep/ .van-icon-arrow-left {
            color: #fff;
        }

        /deep/ .van-nav-bar__text {
            font-size: 16px;
            color: #fff;
        }
    }

    .content {
        padding: 0 15px;

        .title {
            padding-left: 16px;
            padding-top: 16px;
            padding-bottom: 12px;
            color: #8f909e;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 1px;
            font-weight: 400;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }

        .van-cell {
            border-radius: unset;
            background: #303C5C;

            // background-color: #27284c;
            &::after {
                height: 1px;
                opacity: 0.1;
                border: 1px solid #FFFFFF;
            }
        }

        /deep/ .van-cell__title {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 18px;
        }

        /deep/ .radio {
            width: 12px;
            height: 12px;
            color: #fff;
        }

        .group {
            margin-bottom: 10px;
        }

        .van-hairline--top-bottom {
            &::after {
                border-width: 0;
            }
        }
    }
}
&.ar-AR{
    .header-menu{
        span .menu-info{
            position: relative;
            left: 320px;
        }
    }
    .content{
        direction: rtl;
       .visbody-fit{
        text-align: right;
       }
       .van-cell-group{
         span{
        float: right;
       }
       }
       .title{
        text-align: right;
       }
      
    }
}
</style>
